// Администратор
export const ADMIN_ROLE = 'Administrator'
// Студент
export const STUDENT_ROLE = 'Student'
// Преподаватель
export const TEACHER_ROLE = 'Teacher'
// Сотрудник
export const EMPLOYEE_ROLE = 'Coworker'
// Сотрудник учебной части
export const A_EMPLOYEE_ROLE = 'Employee_ed_department'
// Сотрудник кафедры
export const D_EMPLOYEE_ROLE = 'Employee_department'
// Оператор расписания
export const SCHEDULE_OPERATOR_ROLE = 'Schedule_operator'
// Староста
export const HEADMAN_ROLE = 'Headman'
// Управляющий старостами
export const HEADMAN_MANAGER_ROLE = 'Headmans_manager'
// Управляющий причинами неявки
export const ABSENCE_MANAGER_ROLE = 'Absence_manager'
// Неизвестная роль
export const UNDEFINED_ROLE = 'Undefined'

export const EMPLOYEE_NAMES = [
  A_EMPLOYEE_ROLE,
  EMPLOYEE_ROLE,
  SCHEDULE_OPERATOR_ROLE,
  D_EMPLOYEE_ROLE,
  ADMIN_ROLE,
]

export const allRoles = {
  admin: ADMIN_ROLE,
  student: STUDENT_ROLE,
  teacher: TEACHER_ROLE,
  employee: EMPLOYEE_ROLE,
  a_employee: A_EMPLOYEE_ROLE,
  d_employee: D_EMPLOYEE_ROLE,
  schedule_operator: SCHEDULE_OPERATOR_ROLE,
}

export const intramuralConductingWaysSlug = 'intramural'
export const remoteConductingWaysSlug = 'remote'
export const mixedConductingWaysSlug = 'mixed'

/** @deprecated */
export const recipientsEmployeeDefaultValue = {
  coworkerMembers: [],
  coworkerFaculties: [],
  coworkerTreeName: [],
}

export const coworkerAccessFilterDefaultValue = {
  coworkerFilter: {
    facultyExternalId: '',
    groups: [],
    structuralDivisionExternalIds: [],
    userIds: [],
  },
}

export const recipientsStudentsDefaultValue = {
  members: [],
  faculty: [],
  allStudentsOnCourse: [],
  studyGroups: [],
  union: [],
  simple: [],
  separation: [],
  membersFullName: [],
}

export const recipientsDefaultValue = {
  ...recipientsStudentsDefaultValue,
  ...recipientsEmployeeDefaultValue,
}

export const PHYS_FACULTY_EXTERNAL_ID = '3'

export const DEFAULT_REALTIME_TIMEOUT = 300

export const MAX_TEXT_EDITOR_CONTENT_LENGTH = 2000

export const LESSON_LENGTH_IN_MINUTE = 95

export const FIRST_MINUTE_IN_DAY = 0

export const LAST_MINUTE_IN_DAY = 1439

export const scoreArr = ['excellent', 'good', 'satisfactory', 'not_passed', 'passed']

export const usersGroups = [
  'Student',
  'Employee_ed_department',
  'Coworker',
  'Schedule_operator',
  'Employee_department',
  'Teacher',
  'Administrator',
]

// Смещение в минутах по UTC для московского сервера
export const BACKEND_TIMEZONE_UTC_MINUTES_OFFSET = 3 * 60

export const MANUAL_PROVIDER_SYSTEM_SLUG = 'manual'

//Tree extensions
export const COURSE_GRADE_SEPARATOR = '&'
export const SEPARATION_TYPE = 'separation'
export const UNION_TYPE = 'union'
export const SIMPLE_TYPE = 'simple'

export const SPECIALIST_GRADE_EXT_ID = 1

export const FIRST_WEEK_PERIODICITY = 'first'
export const SECOND_WEEK_PERIODICITY = 'second'
export const EVERY_WEEK_PERIODICITY = 'every'

export const USER_STATUS = {
  STATUS_NEW: 'new',
  STATUS_INVITED: 'invited',
  STATUS_REGISTRATION_FINISHED: 'registration_finished',
}

export const USER_STATUS_NAMES = {
  new: 'Новый',
  invited: 'Приглашён',
  registration_finished: 'Завершил регистрацию',
}

export const ABSENCE_STATUS_SLUG = {
  NEW: 'new',
  NEED_REVIEW: 'need_review',
  ON_REVIEW: 'on_review',
  VERIFIED: 'verified',
}

export const ABSENCE_REASON_SLUG = {
  UNKNOWN: 'unknown',
  ACCEPTABLE: 'acceptable',
  UNACCEPTABLE: 'unacceptable',
  UPDATED: 'updated',
}

export const VISIT_STATUS_CODES = {
  VISITED: 'visited',
  WAS_AWAY: 'was_away',
  WAS_LATE: 'was_late',
  RESPECTFUL_REASON: 'respectful_reason',
}

export const SEMESTER_VALUES = [
  {
    id: 'spring',
    name: 'Весенний',
  },
  {
    id: 'fall',
    name: 'Осенний',
  },
]

export const SCORES_VALUES = {
  5: 5,
  4: 4,
  3: 3,
  2: 'Н',
  1: 'З',
}

export const SETTINGS_CATEGORIES = {
  ONLINE_PLATFORM: 'online_platform',
}

export const STUDENT_DEFAULT_APPEALS_FIELDS = {
  text: {
    multiple: false,
    name: 'Текст обращения',
    required: true,
    type: 'text',
  },
  files: {
    multiple: false,
    name: 'Приложенные файлы',
    required: false,
    type: 'files',
  },
}

export const DEFAULT_APPEALS_FIELDS = {
  text: {
    multiple: false,
    name: 'Текст обращения',
    required: true,
    type: 'text',
  },
  course_id: {
    entity_code: 'course',
    multiple: false,
    name: 'Курс',
    required: false,
    type: 'handbook',
  },
  grade_id: {
    entity_code: 'preparationTypes',
    multiple: false,
    name: 'Вид подготовки',
    required: false,
    type: 'handbook',
  },
  files: {
    multiple: false,
    name: 'Приложенные файлы',
    required: false,
    type: 'files',
  },
}

export const FINANCE_SOURCE_CONTRACT_EXTERNAL_IDS = ['49', '25']
